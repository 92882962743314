import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { styled as styling } from "@mui/material/styles";

export const SkeletonStyled = styling(Skeleton)({});

export const EmptyContainer = styling(Grid)({});

export const CardStyled = styling(Card)((props) => ({
  width: "100%",
  display: "flex",
  boxShadow: "none",
}));
