import { useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { localisation } from "../../../helpers/constant";
import { getItem } from "../../../helpers/data_management";
import { getLocaleCopies } from "../../../helpers/locale_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import { updateShowOnboardingDialog } from "../../../redux/actions/dialog_action";
import {
  applyJob,
  cancelJobApplication,
} from "../../../redux/actions/job_action";
import { getUser } from "../../../redux/actions/user_action";
import * as jobType from "../../../redux/types/job_type";
import * as userType from "../../../redux/types/user_type";
import {
  ArrowIcon,
  AttachMoneyIconStyled,
  BusinessCenterIconStyled,
  ButtonStyled,
  CardActionsStyled,
  CardContentStyled,
  CardMediaStyled,
  CardStyled,
  ChipStyled,
  EmptyContainer,
  FireIcon,
  IconDetails,
  LocationOnIconStyled,
  TypographyStyled,
} from "./styles";

function JobCard(props) {
  const { job, homepage, jobList, origin } = props;

  const dispatch = useDispatch();
  const user = getUser();

  const fieldsIncomplete = !Object.values(user?.checkEmpty || {}).every(
    (field) => field === true
  );

  const mobileView = useMediaQuery("(max-width:767px)");
  const applyingJob = useSelector((state) => state.jobs.applyingJob);
  const companyLogo = job?.company?.logo?.includes("default")
    ? `https://s3.ap-southeast-1.amazonaws.com/aset.naikgaji.com/images/job-banners/default-banner.png`
    : job?.company?.logo;

  const reduxUser = useSelector((state) => state.user.user);
  const userOnboarding = reduxUser?.onboarding;

  const router = useRouter();

  const [triggerSnackbarFunc] = useTriggerSnackbar();

  const [alreadyApplied, setAlreadyApplied] = useState(job?.haveApplied);

  const [isLoading, setIsLoading] = useState(false);

  const copies = getLocaleCopies();

  const redirectJob = (link) => {
    sendTrackingEvent({
      event: "CE_job-view",
      "job-id": job?.id,
    });

    Cookies.set("click-source", origin);

    setTimeout(() => {
      Cookies.set("click-source", null);
    }, 30000);

    window.open(`/jobs/${link}`);
  };

  const applyHandler = async () => {
    setIsLoading(true);
    trackApplyClick();

    if (job.scraped !== "") {
      if (getItem(userType.USER_PROFILE) !== null) {
        window.open(job.externalJobUrl);
      } else {
        router.push("/login");
        triggerSnackbarFunc({
          message: "Login Required",
          severity: "error",
        });
      }

      setIsLoading(false);
    } else {
      if (user) {
        if (!userOnboarding || fieldsIncomplete) {
          dispatch(updateShowOnboardingDialog(true));
          setIsLoading(false);
          return;
        }
      }

      const response = await dispatch(
        applyJob({
          jobId: job.id,
        })
      );

      setIsLoading(false);

      if (response.type === jobType.APPLY_JOB_FAILED) {
        triggerSnackbarFunc({
          message: response.errMsg ?? copies.errorAndTryAgain,
          severity: "error",
        });
      } else if (response.type === jobType.LOGIN_REQUIRED_TO_APPLY) {
        router.push("/login");
        triggerSnackbarFunc({
          message: response.errMsg ?? copies.errorAndTryAgain,
          severity: "error",
        });
      } else if (response.type === jobType.APPLY_JOB_SUCCEED) {
        setAlreadyApplied(true);
        triggerSnackbarFunc({
          message: response.errMsg ?? copies.applyJob,
          severity: "success",
        });
      }
    }
  };

  const cancelJobApplyHandler = async () => {
    setIsLoading(true);
    const targetJobId = job?.id;

    const result = await dispatch(cancelJobApplication({ id: targetJobId }));

    setIsLoading(false);

    if (result.type === jobType.CANCEL_JOB_APPLICATION_FAILED) {
      triggerSnackbarFunc({
        message: copies.errorAndTryAgain,
        severity: "error",
      });
    }
  };

  const handleSendTrackingEvent = () => {
    let originStr = null;
    // jobs page
    if (router.asPath.includes("jobs")) {
      originStr = "job_list";
      // profile page
    } else if (router.asPath.includes("profile")) {
      originStr = "profile";
      // home page
    } else {
      originStr = "homepage";
    }

    sendTrackingEvent({
      event: "CE_click-job",
      "job-id": job?.id ?? null,
      origin: originStr,
    });
  };

  const trackImpressionInView = () => {
    let originStr = null;
    // jobs page
    if (router.asPath.includes("jobs")) {
      originStr = "job_list";
      // profile page
    } else if (router.asPath.includes("profile")) {
      originStr = "profile";
      // home page
    } else {
      originStr = "homepage";
    }

    sendTrackingEvent({
      event: "CE_job-impression",
      "job-id": job?.id ?? null,
      origin: originStr,
    });
  };

  const trackApplyClick = () => {
    if (origin === "homepage_trending") {
      sendTrackingEvent({
        event: "CE_click-apply-homepage-trending",
        "job-id": job?.id,
        "logged-in": user ? true : false,
      });
    } else if (origin === "job_list") {
      sendTrackingEvent({
        event: "CE_click-apply-jlp",
        "job-id": job?.id,
        "logged-in": user ? true : false,
      });
    }
  };

  function truncateText(text) {
    const maxLength = 50;
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength - 3) + "... ";
  }

  return (
    <CardStyled
      homepage={homepage}
      jobList={jobList}
      isUserProfile={router.asPath.includes("profile")}
      onClick={handleSendTrackingEvent}
    >
      <EmptyContainer sx={{ display: "flex", alignItems: "center" }}>
        <CardMediaStyled
          default={companyLogo.includes("default")}
          onClick={() => redirectJob(job?.slug)}
          homepage={homepage}
          jobList={jobList}
          component="img"
          alt={job?.company?.name}
          image={companyLogo}
        />
      </EmptyContainer>
      <CardContentStyled
        homepage={homepage}
        onClick={() => redirectJob(job?.slug)}
      >
        <EmptyContainer container width="100%" gap="10px">
          {job?.boosted && (
            <ChipStyled
              solid={true}
              label={
                <EmptyContainer
                  container
                  justifyContent="center"
                  alignItems="center"
                  gap="4px"
                >
                  <FireIcon />

                  <TypographyStyled
                    variant="overline"
                    fontWeight="400px"
                    fontSize="10px"
                    letterSpacing="1.5px"
                  >
                    FEATURED
                  </TypographyStyled>
                </EmptyContainer>
              }
              size="small"
            />
          )}

          <ChipStyled
            variant="outlined"
            label={
              <TypographyStyled
                variant="overline"
                fontWeight="400px"
                fontSize="10px"
                letterSpacing="1.5px"
              >
                {router.locale === localisation.BAHASA_MALAYSIA
                  ? job?.jobType?.titleBm
                  : job?.jobType?.title}
              </TypographyStyled>
            }
            size="small"
          />
        </EmptyContainer>
        <EmptyContainer
          container
          flexDirection="row"
          width="100%"
          gap={{ xs: "10px", tablet: "3px" }}
          justifyContent="space-between"
          paddingBottom={mobileView ? "5px" : ""}
        >
          <EmptyContainer container direction="column">
            <InView
              trackVisibility={true}
              threshold={0.8}
              delay={300}
              onChange={(inView) => inView === true && trackImpressionInView()}
            >
              <TypographyStyled
                variant="h6"
                fontSize={mobileView ? "16px" : "18px"}
                fontWeight="bold"
              >
                {job?.title}
              </TypographyStyled>
            </InView>

            <TypographyStyled>
              {job.scraped === "" ? job?.company?.name : job?.scraped}
            </TypographyStyled>
          </EmptyContainer>
        </EmptyContainer>

        <EmptyContainer container flexDirection="column" gap="2px">
          <IconDetails container flexDirection="row" alignItems="center">
            <AttachMoneyIconStyled style={{ color: "#800000" }} />
            <TypographyStyled sx={{ color: "#800000", fontSize: "14px" }}>
              {job?.salary}
              {job?.salary === "Undisclosed"
                ? ""
                : ` / ${
                    router.locale === localisation.BAHASA_MALAYSIA
                      ? job?.salaryCategory?.titleBm
                      : job?.salaryCategory?.title
                  }`}
            </TypographyStyled>
          </IconDetails>

          {Array.isArray(job?.tracks) && job?.tracks?.length > 0 && (
            <IconDetails container flexDirection="row" alignItems="center">
              <BusinessCenterIconStyled />
              <EmptyContainer container width={"85%"}>
                {job?.tracks.map((track, index) => {
                  return (
                    <TypographyStyled
                      sx={{
                        fontSize: "14px",
                        marginRight: "5px",
                      }}
                      key={index}
                    >
                      {router.locale === localisation.BAHASA_MALAYSIA
                        ? track?.titleBm
                        : track?.title}
                      {index !== job?.tracks?.length - 1 && ","}
                    </TypographyStyled>
                  );
                })}
              </EmptyContainer>
            </IconDetails>
          )}

          <IconDetails container flexDirection="row" alignItems="center">
            <LocationOnIconStyled />
            <TypographyStyled sx={{ fontSize: "14px" }}>
              {(job?.location && truncateText(job?.location)) +
                ", " +
                job?.stateRegion}
            </TypographyStyled>
          </IconDetails>
        </EmptyContainer>
      </CardContentStyled>
      <CardActionsStyled homepage={homepage}>
        <EmptyContainer
          container
          flexDirection={mobileView ? "column-reverse" : "column"}
          justifyContent={job.scraped === "" ? "flex-end" : "space-between"}
          height="100%"
          alignItems={mobileView || homepage ? "center" : "flex-end"}
        >
          {job.scraped === "" ? null : (
            <TypographyStyled
              variant="h6"
              fontSize={"10px"}
              fontWeight="600"
              letterSpacing={"1.5px"}
              textTransform={"uppercase"}
              marginTop={mobileView ? "5px" : "0px"}
            >
              from partner platform
            </TypographyStyled>
          )}
          <ButtonStyled
            id="mohon"
            homepage={homepage}
            loading={applyingJob || isLoading}
            variant="contained"
            onClick={applyHandler}
            disabled={
              applyingJob ||
              isLoading ||
              job.applicationStatus === "SENT" ||
              alreadyApplied === true
            }
          >
            {job.applicationStatus === "SENT" || alreadyApplied === true
              ? copies.appliedButtonText
              : copies.applyButtonText}
            {job.scraped === "" ? null : <ArrowIcon />}
          </ButtonStyled>
        </EmptyContainer>
      </CardActionsStyled>
    </CardStyled>
  );
}
export default JobCard;
