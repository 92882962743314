import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const Wrapper = styling(Grid)({
  backgroundColor: Color.backgroundGrey,
});

export const CardStyled = styling(Card)((props) => ({
  width: "100%",
  maxWidth: props.homepage ? "330px" : props.isUserProfile ? null : "900px",
  display: "flex",
  flexDirection: props.homepage ? "column" : "row",
  borderRadius: "8px",
  minHeight: props.homepage ? "auto" : "200px",
  boxShadow: props.isUserProfile ? `1px 1px 3px ${Color.grey}` : "none",

  ":hover": {
    boxShadow: `4px 4px 8px rgba(0, 0, 0, 0.25);`,
  },

  [props.theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    height: "auto",
  },
}));

export const CardActionsStyled = styling(CardActions)((props) => ({
  padding: "14px",
  minWidth: "200px",
  height: props.homepage ? "auto" : "auto",
  display: "flex",
  alignItems: "flex-end",

  [props.theme.breakpoints.down("tablet")]: {
    height: "auto",
  },
}));

export const CardContentStyled = styling(CardContent)((props) => ({
  width: "100%",
  minHeight: props.homepage ? "206px" : "200px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  cursor: "pointer",
  padding: "14px",

  [props.theme.breakpoints.down("tablet")]: {
    height: "100%",
  },
}));

export const CardMediaStyled = styling(CardMedia)((props) => ({
  minHeight: "200px",
  minWidth: "200px",
  objectFit: props.default ? "cover" : "contain",
  cursor: "pointer",
  width: props.homepage ? "100%" : "200px",
  maxHeight: props.homepage ? "200px" : null,

  [props.theme.breakpoints.up("tablet")]: {
    height: props.jobList === true ? "200px" : null,
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "100%",
    maxHeight: "200px",
  },
}));

export const ButtonStyled = styling(LoadingButton)((props) => ({
  textTransform: "none",
  borderRadius: "6px",
  fontWeight: "bold",
  width: props.homepage ? "60%" : "80%",
  zIndex: 10,

  ":hover": {
    backgroundColor: Color.taglineGreen,
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "50%",
    maxWidth: "250px",
  },
}));

export const TypographyStyled = styling(Typography)({});

export const AttachMoneyIconStyled = styling(AttachMoneyIcon)({
  fontSize: "16px",
});

export const BusinessCenterIconStyled = styling(BusinessCenterIcon)({
  fontSize: "16px",
});

export const LocationOnIconStyled = styling(LocationOnIcon)({
  fontSize: "16px",
});

export const EmptyContainer = styling(Grid)({});

export const IconDetails = styling(Grid)({
  // maxWidth: "200px",
  gap: "20px",
  justifyContent: "flex-start",
});

export const ChipStyled = styling(Chip)((props) => ({
  borderRadius: "4px",
  height: "25px",
  backgroundColor: props.solid ? Color.magenta : "",
  color: props.solid ? "white" : Color.taglineGreen,
  border: props.solid ? "" : `0.5px solid ${Color.taglineGreen}`,
}));

export const FireIcon = styling(WhatshotIcon)({
  color: "14px",
  fontSize: "16px",
});

export const ArrowIcon = styling(ArrowTopRightOnSquareIcon)({
  height: "16px",
  width: "18px",
  marginLeft: "8px",
});
