import { useRouter } from "next/router";
import React from "react";
import { CardStyled, EmptyContainer, SkeletonStyled } from "./styles";

export const JobCardSkeletonDesktop = () => {
  const router = useRouter();

  return (
    <EmptyContainer
      container
      direction="row"
      height="180px"
      width={"100%"}
      maxWidth={router.asPath.includes("profile") ? "1920px" : "900px"}
      justifyContent="space-between"
    >
      <CardStyled>
        {/* Image */}

        <EmptyContainer
          height="100%"
          display="flex"
          overflow="hidden"
          padding="0px"
        >
          <SkeletonStyled variant="rectangular" width={180} height={180} />
        </EmptyContainer>

        <EmptyContainer
          container
          direction="row"
          flex={1}
          height="100%"
          justifyContent="space-between"
        >
          {/* Details */}

          <EmptyContainer
            container
            direction="column"
            justifyContent="space-between"
            width="300px"
            height="auto"
            padding="12px"
          >
            <EmptyContainer container direction="column">
              <SkeletonStyled
                variant="rectangular"
                style={{ width: "70px", height: "25px", borderRadius: "6px" }}
              />
              <SkeletonStyled
                variant="text"
                style={{ width: "90%", borderRadius: "8px", height: "30px" }}
              />
              <SkeletonStyled variant="text" style={{ width: "60%" }} />
            </EmptyContainer>

            <EmptyContainer container direction="column">
              <SkeletonStyled variant="text" style={{ width: "70%" }} />
              <SkeletonStyled variant="text" style={{ width: "70%" }} />
              <SkeletonStyled variant="text" style={{ width: "70%" }} />
            </EmptyContainer>
          </EmptyContainer>

          {/* Chip and Apply button */}

          <EmptyContainer
            container
            direction="column"
            alignItems="flex-end"
            justifyContent="flex-end"
            width="150px"
            height="100%"
            padding="12px"
          >
            <SkeletonStyled
              variant="rectangular"
              style={{ width: "120px", height: "30px", borderRadius: "6px" }}
            />
          </EmptyContainer>
        </EmptyContainer>
      </CardStyled>
    </EmptyContainer>
  );
};

export const JobCardSkeletonMobile = () => {
  const router = useRouter();
  return (
    <EmptyContainer width={router.asPath.includes("/") ? "330px" : "100%"}>
      <CardStyled>
        {/* Image */}
        <EmptyContainer container direction="column" height="100%" width="100%">
          <EmptyContainer
            width="100%"
            height="100%"
            display="flex"
            overflow="hidden"
            padding="0px"
          >
            <SkeletonStyled
              variant="rectangular"
              style={{ width: "100%" }}
              height={200}
            />
          </EmptyContainer>

          <EmptyContainer
            container
            height="100%"
            direction="row"
            justifyContent="space-between"
          >
            {/* Details */}
            <EmptyContainer
              container
              width="100%"
              justifyContent="flex-start"
              alignContent="flex-start"
              padding="0px 16px 0px 16px"
            >
              <SkeletonStyled
                style={{ width: "70px", height: "40px", borderRadius: "16px" }}
              />
            </EmptyContainer>

            <EmptyContainer
              container
              direction="column"
              justifyContent="space-between"
              width="100%"
              height="auto"
              padding="0px 16px 16px 16px"
            >
              <EmptyContainer container direction="column">
                <SkeletonStyled style={{ width: "80%", height: "40px" }} />
                <SkeletonStyled style={{ width: "50%", height: "30px" }} />
              </EmptyContainer>

              <EmptyContainer container direction="column">
                <SkeletonStyled style={{ width: "60%", height: "30px" }} />
                <SkeletonStyled style={{ width: "60%", height: "30px" }} />
                <SkeletonStyled style={{ width: "60%", height: "30px" }} />
              </EmptyContainer>
            </EmptyContainer>
          </EmptyContainer>

          {/* Apply button */}
          <EmptyContainer
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            padding="16px"
          >
            <SkeletonStyled style={{ width: "60%", height: "55px" }} />
          </EmptyContainer>
        </EmptyContainer>
      </CardStyled>
    </EmptyContainer>
  );
};
